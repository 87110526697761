<template>
    <div class="p-fluid formgrid grid form">
        <div class="field col-12">
            <label for="">Nome</label>
            <InputText type="text" v-model="state.name" />
        </div>

        <div class="field col-12">
            <label for="">Usuário</label>
            <InputText type="text" v-model="state.username" />
        </div>

        <div class="field col-12">
            <label for="">Senha</label>
            <Password v-model="state.password" :feedback="false" toggleMask />
        </div>

        <div class="field col-12">
            <Button :loading="state.loading" label="Criar Usuário" icon="pi pi-check" iconPos="right"
                @click="tryToCreateUSer" />
        </div>
    </div>
</template>

<script>
import InputText from 'primevue/inputtext'
import Password from 'primevue/password'
import Button from 'primevue/button'
import services from '../../services'
import { reactive, } from 'vue'
import { useStore } from 'vuex'
import useNotificationToast from '../../composables/useNotificationToast'

export default {

    components: { InputText, Password, Button },

    setup() {

        const state = reactive({
            name: null,
            username: null,
            password: null,
            loading: false,
        })

        const store = useStore()
        const { success, error } = useNotificationToast()


        async function tryToCreateUSer() {

            state.loading = true

            if (!state.name || !state.username || !state.password) {
                state.loading = false
                return error('Verifique todos os campos!')
            }

            const token = store.getters.getToken

            await services.users.create({
                name: state.name,
                login: state.username,
                password: state.password,
                token
            }).then(() => {
                success('Usuário criado com sucesso!')
                state.name = null
                state.username = null
                state.password = null
            })
                .catch(() => {
                    error('Não foi possível criar o usuário.')
                })
                .finally(() => { state.loading = false })

            state.loading = false
        }


        return {
            state,
            tryToCreateUSer
        }
    }
}

</script>

<style lang="scss" scoped>
.form {
    max-width: 500px;
    margin: 0 auto;
}
</style>